import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import axios from 'axios';

Vue.config.productionTip = false;

const axios_instance = axios.create({
	baseURL: process.env.VUE_APP_API_URL + '/api',
});

// Add a response interceptor
axios_instance.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response.status === 401) {
			router.push('/login');
		}

		return Promise.reject(error);
	}
);

store.axios = axios_instance;

const user = JSON.parse(localStorage.getItem('user'));

if (user) {
	axios_instance.defaults.headers.common['Authorization'] = user.accessToken;
}

new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: function (h) {
		return h(App);
	},
}).$mount('#app');
