import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Dashboard',
		component: () => import('../views/DashboardView.vue'),
	},
	{
		path: '/admin',
		name: 'Admin',
		component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminView.vue'),
		children: [
			{
				path: '/admin/management',
				name: 'AdminManagement',
				component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminManagement.vue')
			},
			{
				path: '/admin/options',
				name: 'AdminOptions',
				component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminOptions.vue')
			},
			{
				path: '/admin/analysis',
				name: 'AdminAnalyse',
				component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminAnalyse.vue')
			},
		],
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/LoginView.vue'),
	},
];

const router = new VueRouter({
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	const publicPages = ['/login'];
	const authRequired = !publicPages.includes(to.path);
	const loggedIn = localStorage.getItem('user');

	// trying to access a restricted page + not logged in
	// redirect to login page
	//TODO Admin check
	if (authRequired && !loggedIn) {
		next('/login');
	} else {
		next();
	}
});

export default router;
