<template>
	<div id="header">
		<div
			class="header__title"
			@click="openDashboard"
		>
			<span>{{ title }}</span>
		</div>

		<div
			class="burger"
			ref="burger"
			@click="toggleNav"
		>
			<span></span>
			<span></span>
			<span></span>
		</div>

		<nav
			class="header-nav"
			ref="mobile"
		>
			<div class="header-nav__inner">
				<ul class="header-menu-list">
					<li class="header-menu-item">
						<router-link to="/admin/analysis">
							<span @click="closeNav">{{ $t('header.analysis') }}</span>
						</router-link>
					</li>
					<li class="header-menu-item">
						<router-link to="/admin/management">
							<span @click="closeNav">{{ $t('header.administration') }}</span>
						</router-link>
					</li>
					<li class="header-menu-item">
						<router-link to="/admin/options">
							<span @click="closeNav">{{ $t('header.options') }}</span>
						</router-link>
					</li>
					<li class="header-menu-item">
						<router-link to="/">
							<span @click="closeNav">{{ $t('header.overview') }}</span>
						</router-link>
					</li>
				</ul>
			</div>
		</nav>

		<v-tabs
			v-if="user"
			right
			class="header__tabs"
			color="whitesmoke"
		>
			<template v-if="checkAdmin">
				<v-tab
					class="header__tabs-tab"
					to="/admin/analysis"
					>{{ $t('header.analysis') }}</v-tab
				>
				<v-tab
					class="header__tabs-tab"
					to="/admin/management"
					>{{ $t('header.administration') }}</v-tab
				>
				<v-tab
					class="header__tabs-tab"
					to="/admin/options"
					>{{ $t('header.options') }}</v-tab
				>
			</template>
			<v-tab
				class="header__tabs-tab"
				to="/"
				>{{ $t('header.dashboard') }}</v-tab
			>
		</v-tabs>
	</div>
</template>
<script>
import { mapState } from 'vuex';

export default {
	name: 'ApoHeader',
	computed: mapState({
		user: (state) => state.auth.user,
		checkAdmin() {
			if (this.user?.roles) {
				return this.user.roles.some((e) => e === 'admin');
			}

			return false;
		},
		title() {
			return process.env.VUE_APP_TITLE || 'Apo Planer';
		},
	}),
	methods: {
		openDashboard() {
			this.$router.push({ path: '/' });
		},
		toggleNav() {
			this.$refs.burger.classList.toggle('--active');
			this.$refs.mobile.classList.toggle('--active');
		},
		closeNav() {
			this.$refs.burger.classList.remove('--active');
			this.$refs.mobile.classList.remove('--active');
		},
	},
};
</script>

<style lang="scss">
@import '../style/variables';

#header {
	display: flex;
	width: 100%;
	min-height: 64px;
	background-color: $header;
	color: $header-text;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	z-index: 99;
	position: sticky;
	top: 0;

	.header__title {
		display: flex;
		cursor: pointer;

		span {
			font-size: 1.25rem;
			line-height: 1.5;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.header__tabs {
		display: none;
		height: 100%;

		.v-item-group {
			background-color: inherit;
			height: inherit;

			.header__tabs-tab {
				color: $header-text;
			}
		}
	}

	.burger {
		display: inline-flex;
		background: transparent;
		transition: none;
		cursor: pointer;
		z-index: 10;
		position: relative;
		width: 24px;
		height: 18px;

		input {
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;

			cursor: pointer;

			opacity: 0; /* hide this */
			z-index: 2; /* and place it over the hamburger */

			-webkit-touch-callout: none;
		}

		span {
			position: absolute;
			width: 100%;
			height: 3px;
			left: 0;
			background-color: white;
			top: 5px;

			&:nth-child(1) {
				top: 0;
				transition: rotate, 0.3s;
			}

			&:nth-child(2) {
				top: 50%;
			}

			&:nth-child(3) {
				top: 100%;
				transition: rotate, 0.3s;
			}
		}

		&.--active span {
			&:nth-child(1) {
				top: 10px;
				right: 0;
				transform: rotate(45deg);
			}

			&:nth-child(2) {
				opacity: 0;
			}

			&:nth-child(3) {
				top: 10px;
				right: 0;
				transform: rotate(-45deg);
			}
		}
	}

	.header-nav {
		display: none;
		opacity: 0;
	}

	.header-nav.--active {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: $header-nav-background-color;
		z-index: 5;
		opacity: 1;
		transition: opacity 0.6s linear;

		.header-nav__inner {
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			align-items: center;
			align-content: center;
			height: 100%;
			overflow: auto;

			.header-menu-list {
				width: 100%;
				text-align: center;
				padding: 0;
				overflow: hidden;
				display: block;

				.header-menu-item {
					padding: 0.5rem 1rem;
					border-radius: 1rem;
					margin-left: auto;
					margin-right: auto;
					width: 80%;
					list-style: none;
					transition: color 0.5s ease-in-out;
					cursor: pointer;

					&:hover {
						background-color: $header-nav-background-color--hover;
						transition: color 0.3s ease-in-out;
					}
				}
			}

			.header-menu-list .header-menu-item a {
				display: inline-block;
				font-size: 14px;
				line-height: 17px;
				color: $primary-text;
				text-transform: uppercase;
				padding: 5px 8px;
				letter-spacing: 1px;
				text-decoration: none;
			}
		}
	}
}

@include tablet-landscape-up {
	#header {
		padding: 0.5rem 1rem;

		.burger {
			display: none;
		}

		.header-nav {
			display: none;
		}

		.header__tabs {
			display: block;
		}
	}
}
</style>
