const state = {
	users: [],
	events: [],
	shifts: [],
	roles: [],
};

const getters = {};

const actions = {
	async initial({ dispatch }) {
		return Promise.all([
			dispatch('getUsers'),
			dispatch('getUserEvents'),
			dispatch('getUserShifts'),
			dispatch('getRoles'),
		]);
	},
	async getUserEvents({ commit }) {
		return this.axios
			.get('/admin/user/event')
			.then((response) => {
				commit('SET_USER_EVENTS', response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	},
	addUserEvent({ commit }, event) {
		this.axios
			.post('/admin/user/event', event)
			.then(() => {})
			.catch((error) => {
				commit('TRIGGER_TOAST', 'Event konnte nicht hinzugefügt werden', {
					root: true,
				});
				console.log(error);
			});
	},
	updateUserEvent({ commit }, event) {
		this.axios
			.put('/admin/user/event', event)
			.then(() => {
				commit('TRIGGER_TOAST', 'Event aktualisiert', {
					root: true,
				});
			})
			.catch(() => {
				commit('TRIGGER_TOAST', 'Event konnte nicht aktualisiert werden', {
					root: true,
				});
			});
	},
	deleteUserEvent({ commit }, payload) {
		this.axios
			.delete('/admin/user/event', {
				params: payload,
			})
			.then(() => {
				commit('TRIGGER_TOAST', 'Event gelöscht', {
					root: true,
				});
			})
			.catch(() => {
				commit('TRIGGER_TOAST', 'Event konnte nicht gelöscht werden', {
					root: true,
				});
			});
	},
	async getUserShifts({ commit }) {
		return this.axios
			.get('/admin/user/shift')
			.then((response) => {
				commit('SET_USER_SHIFTS', response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	},
	addUserShift({ commit }, shifts) {
		this.axios
			.post('/admin/user/shift', shifts)
			.then(() => {})
			.catch(() => {
				commit('TRIGGER_TOAST', 'Schicht konnte nicht hinzugefügt werden', {
					root: true,
				});
			});
	},
	deleteUserShift({ commit }, payload) {
		this.axios
			.delete('/admin/user/shift', {
				params: payload,
			})
			.then(() => {})
			.catch(() => {
				commit('TRIGGER_TOAST', 'Schicht konnte nicht gelöscht werden', {
					root: true,
				});
			});
	},
	async getRoles({ commit }) {
		return this.axios
			.get('/admin/user/roles')
			.then((response) => {
				commit('SET_ROLES', response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	},
	async getUsers({ commit }) {
		return this.axios
			.get('/admin/user')
			.then((response) => {
				commit('SET_USERS', response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	},
	addUser({ commit }, user) {
		this.axios
			.post('/admin/user', user)
			.then(() => {
				commit('TRIGGER_TOAST', 'Mitarbeiter angelegt', { root: true });
			})
			.catch((error) => {
				console.log(error);
				commit('TRIGGER_TOAST', 'Mitarbeiter konnte nicht angelegt werden', {
					root: true,
				});
			});
	},
	updateUser({ commit }, user) {
		this.axios
			.put('/admin/user', user)
			.then(() => {
				commit('TRIGGER_TOAST', 'Mitarbeiter aktualisiert', { root: true });
			})
			.catch((error) => {
				console.log(error);
				commit(
					'TRIGGER_TOAST',
					'Mitarbeiter konnte nicht aktualisiert werden',
					{ root: true }
				);
			});
	},
	deleteUser({ commit }, user) {
		this.axios
			.delete('/admin/user', { params: { id: user._id } })
			.then(() => {
				commit('TRIGGER_TOAST', 'Mitarbeiter gelöscht', { root: true });
			})
			.catch((error) => {
				console.log(error);
				commit('TRIGGER_TOAST', 'Mitarbeiter konnte nicht gelöscht werden', {
					root: true,
				});
			});
	},
	resetUserPassword({ commit }, user) {
		this.axios
			.post('/admin/user/resetUserPassword', {
				_id: user._id,
				password: user.password,
			})
			.then(() => {
				commit('TRIGGER_TOAST', 'Passwort gespeichert', { root: true });
			})
			.catch((error) => {
				console.log(error);
				commit('TRIGGER_TOAST', 'Passwort konnte nicht gespeichert werden', {
					root: true,
				});
			});
	},
};

const mutations = {
	SET_USERS(state, payload) {
		state.users = payload;
	},
	SET_USER_EVENTS(state, payload) {
		state.events = payload;
	},
	UPDATE_USER_EVENTS(state, payload) {
		if (Array.isArray(payload)) {
			state.events = state.events.concat(payload);
		} else {
			state.events.push(payload);
		}
	},
	UPDATE_USER_EVENT(state, payload) {
		state.events = state.events.map((event) =>
			event._id === payload._id ? payload : event
		);
	},
	DELETE_USER_EVENTS(state, payload) {
		if (Array.isArray(payload)) {
			payload.forEach((id) => {
				const index = state.events.findIndex((e) => e._id === id);
				state.events.splice(index, 1);
			});
		} else {
			const index = state.events.findIndex((e) => e._id === payload);
			state.events.splice(index, 1);
		}
	},
	SET_USER_SHIFTS(state, payload) {
		state.shifts = payload;
	},
	UPDATE_USER_SHIFTS(state, payload) {
		if (Array.isArray(payload)) {
			state.shifts = state.shifts.concat(payload);
		} else {
			if (state.shifts.some((e) => e._id === payload._id)) {
				state.shifts = state.shifts.map((shift) =>
					shift._id === payload._id ? payload : shift
				);
			} else {
				state.shifts.push(payload);
			}
		}
	},
	DELETE_USER_SHIFT(state, payload) {
		if (Array.isArray(payload)) {
			payload.forEach((id) => {
				const index = state.shifts.findIndex((e) => e._id === id);
				state.shifts.splice(index, 1);
			});
		} else {
			const index = state.shifts.findIndex((e) => e._id === payload);
			state.shifts.splice(index, 1);
		}
	},
	SET_ROLES(state, payload) {
		state.roles = payload;
	},
	ADD_USER(state, payload) {
		state.users.push(payload);
	},
	DELETE_USER(state, payload) {
		if (payload) {
			const index = state.users.findIndex((u) => u._id === payload);
			state.users.splice(index, 1);
		}
	},
	UPDATE_USER(state, payload) {
		state.users = state.users.map((u) => (u._id === payload._id ? payload : u));
	},
	ADD_EVENT(state, payload) {
		state.events.push(payload);
	},
	ADD_SHIFT(state, payload) {
		state.shifts.push(payload);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
