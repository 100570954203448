<template>
	<v-app id="app">
		<apo-header />
		<div
			class="app__loading-screen"
			v-if="loading"
		>
			<v-progress-circular
				:size="70"
				color="accent"
				width="6"
				indeterminate
			/>
		</div>
		<div
			v-else
			id="app__body"
		>
			<router-view />
			<v-snackbar
				v-model="$store.state.openToast"
				bottom
				right
				timeout="2000"
				color="#363636"
				class="snackbar"
				>{{ $store.state.textToast }}</v-snackbar
			>
		</div>
		<apo-footer />
	</v-app>
</template>

<script>
import ApoHeader from './components/ApoHeader.vue';
import ApoFooter from './components/ApoFooter.vue';

export default {
	name: 'App',
	components: {
		ApoFooter,
		ApoHeader,
	},
	computed: {
		loading() {
			const busy = ["initial", "loading"];

			return busy.includes(this.$store.state.auth.status);
		}
	},
	beforeCreate() {
		const user = localStorage.getItem('user');
		if (user) {
			this.$store
				.dispatch('auth/checkSession')
				.then(() => {
					this.$store.commit('TRIGGER_TOAST', 'Authorized');

					if (this.$route.path !== '/') {
						this.$router.push({ path: '/' });
					}
				})
				.catch(() => {
					this.$store.state.auth.user = null;
					localStorage.removeItem('user');

					if (this.$route.path !== '/login') {
						this.$router.push({ path: '/login' });
					}
				});
		} else {
			if (this.$route.path !== '/login') {
				this.$router.push({ path: '/login' });
			}

			this.$store.commit("auth/SET_STATUS", "unauthorized");
		}
	},
};
</script>

<style lang="scss">
@import 'src/style/addons/scrollbar';

html,
body,
#app {
	height: 100%;
}

.app__loading-screen {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

#app__body {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}
.app__header {
	background-color: #fff;
}

body .v-application {
	.v-menu__content:not(.default):not(.v-autocomplete__content) .v-list {
		background-color: $dropdown-background;

		.v-list-item {
			color: $secondary-text !important;

			> span {
				color: $secondary-text;
			}

			.v-icon {
				color: $secondary-text;
			}
		}
	}

	.primary--text {
		color: $primary-text !important; //VUETIFY OVERRIDE!!!
	}

	.theme--light.v-input--is-disabled {
		color: red;
	}
}

@media screen and (min-width: 1800px) {
	#app #app__body {
		width: 1800px;
		margin: auto;
		//align-items: center;
	}
}
</style>
