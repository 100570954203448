<template>
	<v-footer
		padless
		id="footer"
	>
		<div class="footer__content">
			<div class="footer__content-version">
				<button @click="showInfo">{{ version }}</button>
			</div>
			<div class="footer__content-cr">
				<span>© {{ new Date().getFullYear() }} - Apoplaner</span>
			</div>
			<div class="footer__content-lang">
				<v-btn
					v-if="$route.path !== '/login'"
					plain
					@click="logout"
					>{{ $t('footer.actions.logout') }}</v-btn
				>
			</div>
		</div>
	</v-footer>
</template>

<script>
export default {
	name: 'ApoFooter',
	computed: {
		version() {
			return process.env.VUE_APP_VERSION || 0;
		}
	},
	methods: {
		logout() {
			this.$store.dispatch('auth/logout').then(() => {
				this.$router.push('/login');
			});
		},
		showInfo() {
			// TODO
		}
	},
};
</script>

<style lang="scss">
@import '../style/variables';

#footer {
	background-color: $footer;
	min-height: 64px;
	max-height: 64px;
	padding: 0 1rem;

	.footer__content {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		color: $footer-text;

		.footer__content-version {
			justify-content: flex-start;
		}

		.footer__content-cr {
			flex: 3 1 50%;
		}

		> div {
			flex: 1 1 33%;
			padding: 0.5rem;
			display: flex;
			justify-content: center;
		}

		.footer__content-lang {
			justify-content: flex-end;

			button {
				color: $footer-text;
			}
		}
	}
}

//min-width: 900px
@include tablet-landscape-up {
	#footer .footer__content {
		.footer__content-cr {
			flex: 1 1 33%;
		}
	}
}
</style>
