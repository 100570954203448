import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth.js';
import analyse from './modules/analyse.js';
import adminManagement from './modules/adminManagement.js';
import adminOptions from '@/store/modules/adminOptions.js';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		openToast: false,
		textToast: '',
	},
	mutations: {
		TRIGGER_TOAST(state, payload) {
			state.textToast = payload;
			state.openToast = !state.openToast;
		},
	},
	actions: {},
	modules: {
		auth,
		analyse,
		adminManagement,
		adminOptions,
	},
});
