const state = {
	events: [],
	shifts: [],
	qualifications: [],
	years: [],
};

const getters = {};

const actions = {
	async initial({ dispatch }) {
		return Promise.all([
			dispatch('getShifts'),
			dispatch('getEvents'),
			dispatch('getQualifications'),
			dispatch('getYears')
		]);
	},
	async getShifts({ commit }) {
		return this.axios
			.get('/index/shifts')
			.then((response) => {
				commit('SET_SHIFTS', response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	},
	addShift({ commit }, shift) {
		this.axios
			.post('/admin/option/shift', shift)
			.then(() => {
				commit('TRIGGER_TOAST', 'Schicht hinzugefügt', { root: true });
			})
			.catch((error) => {
				console.log(error);
				commit('TRIGGER_TOAST', 'Schicht konnte nicht hinzugefügt werden', {
					root: true,
				});
			});
	},
	updateShift({ commit }, shift) {
		this.axios
			.put('/admin/option/shift', shift)
			.then(() => {
				commit('TRIGGER_TOAST', 'Schicht aktualisiert', { root: true });
			})
			.catch((error) => {
				console.log(error);
				commit('TRIGGER_TOAST', 'Schicht konnte nicht aktualisiert werden', {
					root: true,
				});
			});
	},
	deleteShift({ commit }, shift) {
		this.axios
			.delete('/admin/option/shift', { params: { id: shift._id } })
			.then(() => {
				commit('TRIGGER_TOAST', 'Schicht gelöscht', { root: true });
			})
			.catch((error) => {
				console.log(error);
				commit('TRIGGER_TOAST', 'Schicht konnte nicht gelöscht werden', {
					root: true,
				});
			});
	},
	async getQualifications({ commit }) {
		return this.axios
			.get('/admin/option/qualification')
			.then((response) => {
				commit('SET_QUALIFICATIONS', response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	},
	addQualification({ commit }, qualification) {
		this.axios
			.post('/admin/option/qualification', qualification)
			.then(() => {
				commit('TRIGGER_TOAST', 'Qualifikation hinzugefügt', { root: true });
			})
			.catch((error) => {
				console.log(error);
				commit(
					'TRIGGER_TOAST',
					'Qualifikation konnte nicht hinzugefügt werden',
					{
						root: true,
					}
				);
			});
	},
	updateQualification({ commit }, qualification) {
		this.axios
			.put('/admin/option/qualification', qualification)
			.then(() => {
				commit('TRIGGER_TOAST', 'Qualifikation aktualisiert', { root: true });
			})
			.catch((error) => {
				console.log(error);
				commit(
					'TRIGGER_TOAST',
					'Qualifikation konnte nicht aktualisiert werden',
					{
						root: true,
					}
				);
			});
	},
	deleteQualification({ commit }, qualification) {
		this.axios
			.delete('/admin/deleteQualification', {
				params: {
					id: qualification._id,
				},
			})
			.then(() => {
				commit('TRIGGER_TOAST', 'Qualifikation gelöscht', { root: true });
			})
			.catch((error) => {
				console.log(error);
				commit('TRIGGER_TOAST', 'Qualifikation konnte nicht gelöscht werden', {
					root: true,
				});
			});
	},
	async getEvents({ commit }) {
		return this.axios
			.get('/index/events')
			.then((response) => {
				commit('SET_EVENTS', response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	},
	addEvent({ commit }, event) {
		this.axios
			.post('/admin/option/event', event)
			.then(() => {
				commit('TRIGGER_TOAST', 'Event hinzugefügt', { root: true });
			})
			.catch((error) => {
				console.log(error);
				commit('TRIGGER_TOAST', 'Event konnte nicht hinzugefügt werden', {
					root: true,
				});
			});
	},
	updateEvent({ commit }, event) {
		this.axios
			.put('/admin/option/event', event)
			.then(() => {
				commit('TRIGGER_TOAST', 'Event aktualisiert', { root: true });
			})
			.catch((error) => {
				console.log(error);
				commit('TRIGGER_TOAST', 'Event konnte nicht aktualisiert werden', {
					root: true,
				});
			});
	},
	deleteEvent({ commit }, event) {
		this.axios
			.delete('/admin/option/event', { params: { id: event._id } })
			.then(() => {
				commit('TRIGGER_TOAST', 'Event gelöscht', { root: true });
			})
			.catch((error) => {
				console.log(error);
				commit('TRIGGER_TOAST', 'Event konnte nicht gelöscht werden', {
					root: true,
				});
			});
	},
	async getYears({ commit }) {
		return this.axios
			.get('/admin/option/year')
			.then((res) => {
				commit('SET_YEARS', res.data);
			})
			.catch((error) => {
				commit(
					'TRIGGER_TOAST',
					'Analyse Jahre konnten nicht abgerufen werden',
					{
						root: true,
					}
				);
				console.log(error);
			});
	},
	addYear({ commit }, { date }) {
		this.axios
			.post('/admin/option/year', {
				date: date,
			})
			.then()
			.catch((error) => {
				commit('TRIGGER_TOAST', 'Jahr konnten nicht angelegt werden', {
					root: true,
				});
				console.log(error);
			});
	},
	deleteYear({ commit }, id) {
		this.axios
			.delete('/admin/option/year', {
				params: {
					id: id,
				},
			})
			.then()
			.catch((error) => {
				commit('TRIGGER_TOAST', 'Jahr konnten nicht gelöscht werden', {
					root: true,
				});
				console.log(error);
			});
	},
};

const mutations = {
	//Event
	SET_EVENTS(state, payload) {
		state.events = payload;
	},
	ADD_EVENT(state, payload) {
		state.events.push(payload);
	},
	UPDATE_EVENT(state, payload) {
		state.events = state.events.map((s) =>
			s._id === payload._id ? payload : s
		);
	},
	DELETE_EVENT(state, payload) {
		const index = state.events.findIndex((e) => e._id === payload);
		if (index >= 0) state.events.splice(index, 1);
	},
	SET_SHIFTS(state, payload) {
		state.shifts = payload;
	},
	ADD_SHIFT(state, payload) {
		state.shifts.push(payload);
	},
	UPDATE_SHIFT(state, payload) {
		state.shifts = state.shifts.map((s) =>
			s._id === payload._id ? payload : s
		);
	},
	DELETE_SHIFT(state, payload) {
		const index = state.shifts.findIndex((e) => e._id === payload);
		if (index >= 0) state.shifts.splice(index, 1);
	},
	SET_QUALIFICATIONS(state, payload) {
		state.qualifications = payload;
	},
	ADD_QUALIFICATION(state, payload) {
		state.qualifications.push(payload);
	},
	UPDATE_QUALIFICATION(state, payload) {
		state.qualifications = state.qualifications.map((q) =>
			q._id === payload._id ? payload : q
		);
	},
	DELETE_QUALIFICATION(state, payload) {
		const index = state.qualifications.findIndex((e) => e._id === payload);
		if (index >= 0) state.qualifications.splice(index, 1);
	},
	SET_YEARS(state, payload) {
		if (payload) {
			state.years = payload;
		}
	},
	ADD_YEAR(state, payload) {
		if (payload) {
			state.years.push(payload);
		}
	},
	DELETE_YEAR(state, payload) {
		if (payload) {
			if (typeof payload === 'object') payload = payload._id;

			const index = state.years.findIndex((e) => e._id === payload);
			if (index >= 0) state.years.splice(index, 1);
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
