import store from '../store/index.js';

const apiBaseUrl = process.env.VUE_APP_API_URL + '/api';
let evtSource;
let status = "initial";

export function stream() {
	startStream();
}

function startStream() {
	evtSource = new EventSource(
		apiBaseUrl + '/source?key=' + store.state.auth.user.accessToken,
		{ withCredentials: false }
	);

	evtSource.onopen = (e) => {
		if (e.isTrusted && status !== "initial") {
			store.dispatch('auth/initialAuth');
		}

		status = "open";
	};

	evtSource.onerror = (e) => {
		console.log('An error occurred while attempting to connect.');

		if (e.target.readyState.CONNECTING) {
			console.log('Reconnecting...');
		} else if (e.target.readyState.CLOSED) {
			reconnect();
			console.log('Connection failed');
		}

		status = "error";
	};

	evtSource.addEventListener('updateUser', (ev) => {
		const user = JSON.parse(ev.data);
		store.commit('adminManagement/UPDATE_USER', user);
		store.commit('auth/UPDATE_USER', user);
	});

	evtSource.addEventListener('addUser', (ev) => {
		const user = JSON.parse(ev.data);
		store.commit('adminManagement/ADD_USER', user);
	});

	evtSource.addEventListener('deleteUser', (ev) => {
		const id = JSON.parse(ev.data);
		store.commit('adminManagement/DELETE_USER', id);
		if (id === store.state.auth.user._id) store.commit('auth/LOGOUT_SUCCESS');
	});

	/**
	 * ADMIN OPTIONS
	 */
	evtSource.addEventListener('addYear', (ev) => {
		const evn = JSON.parse(ev.data);
		store.commit('adminOptions/ADD_YEAR', evn);
	});

	evtSource.addEventListener('deleteYear', (ev) => {
		const evn = JSON.parse(ev.data);
		store.commit('adminOptions/DELETE_YEAR', evn);
	});

	//Shift
	evtSource.addEventListener('updateShift', (ev) => {
		const shift = JSON.parse(ev.data);
		store.commit('adminOptions/UPDATE_SHIFT', shift);
	});

	evtSource.addEventListener('addShift', (ev) => {
		const shift = JSON.parse(ev.data);
		store.commit('adminOptions/ADD_SHIFT', shift);
	});

	evtSource.addEventListener('deleteShift', (ev) => {
		const id = JSON.parse(ev.data);
		store.commit('adminOptions/DELETE_SHIFT', id);
	});

	//Qualification
	evtSource.addEventListener('updateQualification', (ev) => {
		const qualification = JSON.parse(ev.data);
		store.commit('adminOptions/UPDATE_QUALIFICATION', qualification);
	});

	evtSource.addEventListener('addQualification', (ev) => {
		const qualification = JSON.parse(ev.data);
		store.commit('adminOptions/ADD_QUALIFICATION', qualification);
	});

	evtSource.addEventListener('deleteQualification', (ev) => {
		const id = JSON.parse(ev.data);
		store.commit('adminOptions/DELETE_QUALIFICATION', id);
	});

	//Event
	evtSource.addEventListener('updateEvent', (ev) => {
		const event = JSON.parse(ev.data);
		store.commit('adminOptions/UPDATE_EVENT', event);
	});

	evtSource.addEventListener('addEvent', (ev) => {
		const event = JSON.parse(ev.data);
		store.commit('adminOptions/ADD_EVENT', event);
	});

	evtSource.addEventListener('deleteEvent', (ev) => {
		const id = JSON.parse(ev.data);
		store.commit('adminOptions/DELETE_EVENT', id);
	});

	//User event
	evtSource.addEventListener('addUserEvent', (ev) => {
		const evn = JSON.parse(ev.data);
		store.commit('adminManagement/UPDATE_USER_EVENTS', evn);
		store.commit('auth/ADD_USER_EVENT', evn);
	});

	evtSource.addEventListener('updateUserEvent', (ev) => {
		const evn = JSON.parse(ev.data);
		store.commit('adminManagement/UPDATE_USER_EVENT', evn);
		store.commit('auth/UPDATE_USER_EVENT', evn);
	});

	evtSource.addEventListener('deleteUserEvent', (ev) => {
		const evn = JSON.parse(ev.data);
		store.commit('adminManagement/DELETE_USER_EVENTS', evn);
		store.commit('auth/DELETE_USER_EVENT', evn);
	});

	//User shift
	evtSource.addEventListener('addUserShift', (ev) => {
		const evn = JSON.parse(ev.data);
		store.commit('adminManagement/UPDATE_USER_SHIFTS', evn);
		store.commit('auth/ADD_USER_SHIFT');
	});

	evtSource.addEventListener('deleteUserShift', (ev) => {
		const evn = JSON.parse(ev.data);
		if (typeof evn === 'object') {
			store.commit('adminManagement/UPDATE_USER_SHIFTS', evn);
		}

		store.commit('adminManagement/DELETE_USER_SHIFT', evn);
		store.commit('auth/DELETE_USER_SHIFT', evn);
	});
}

function reconnect() {
	setTimeout(() => {
		startStream();
	}, 5000);
}
