import { stream } from '../../stream';

const state = {
	status: 'initial',
	user: JSON.parse(localStorage.getItem('user')),
	userShifts: [],
	userEvents: [],
	index: {
		shifts: [],
		events: [],
		years: [],
		states: [],
		publicHolidays: {},
	},
};

const getters = {};

const actions = {
	async checkSession({ commit, state, dispatch }) {
		commit('SET_STATUS', 'loading');

		try {
			const res = await this.axios
			.get('/session');

			res.data.accessToken = state.user.accessToken;
			commit('UPDATE_USER', res.data);
			//Eventsource
			stream();

			await dispatch('initialAuth');
			commit('SET_STATUS', 'success');
			return;
		} catch (e) {
			commit('LOGIN_FAILURE', e);
			commit('TRIGGER_TOAST', 'Unauthorized', { root: true });
			return Promise.reject(e);
		}
	},
	async login({ commit, dispatch }, user) {
		commit('SET_STATUS', 'loading');

		try {
			const res = await this.axios.post('/session', {
				email: user.email,
				password: user.password,
			});

			commit('LOGIN_SUCCESS', res.data);
			stream();

			await dispatch('initialAuth');
			commit('SET_STATUS', 'success');
			return;
		} catch (e) {
			commit('LOGIN_FAILURE', e);
			commit('TRIGGER_TOAST', 'Unauthorized', { root: true });
			return Promise.reject(e);
		}
	},
	async initialAuth({ dispatch, state }) {

		//Info
		const userPromises = [
			dispatch('getAvailableEvents'),
			dispatch('getAvailableShifts'),
			dispatch('getAvailableYears'),
			dispatch('getStates'),
			dispatch('getPublicHolidays', new Date().getFullYear()),
			dispatch('getUserEvents'),
			dispatch('getUserShifts'),
		];

		//Admin
		if (state.user.roles.some((e) => e === 'admin')) {
			userPromises.push(
				dispatch('adminManagement/initial', null, { root: true })
			);
			userPromises.push(dispatch('adminOptions/initial', null, { root: true }));
		}

		return Promise.all(userPromises);
	},
	logout({ commit }) {
		commit('LOGOUT_SUCCESS');
	},
	async getUserShifts({ commit }) {
		return this.axios
			.get('/user/shift')
			.then((response) => {
				commit('SET_USER_SHIFTS', response.data);
			})
			.catch(() => {
				commit('TRIGGER_TOAST', 'Schichten konnten nicht abegrufen werden', {
					root: true,
				});
			});
	},
	async getUserEvents({ commit }) {
		return this.axios
			.get('/user/event')
			.then((response) => {
				commit('SET_USER_EVENTS', response.data);
			})
			.catch(() => {
				commit('TRIGGER_TOAST', 'Events konnten nicht abegrufen werden', {
					root: true,
				});
			});
	},
	async getAvailableYears({ commit }) {
		return this.axios
			.get('/index/years')
			.then((response) => {
				commit('SET_INDEX', {
					data: response.data,
					key: 'years',
				});
			})
			.catch((error) => {
				console.log(error);
			});
	},
	async getAvailableShifts({ commit }) {
		return this.axios
			.get('/index/shifts')
			.then((response) => {
				commit('SET_INDEX', {
					data: response.data,
					key: 'shifts',
				});
			})
			.catch((error) => {
				console.log(error);
			});
	},
	async getAvailableEvents({ commit }) {
		return this.axios
			.get('/index/events')
			.then((response) => {
				commit('SET_INDEX', {
					data: response.data,
					key: 'events',
				});
			})
			.catch((error) => {
				console.log(error);
			});
	},
	async getStates({ commit }) {
		return this.axios
			.get('/index/states?country=de')
			.then((response) => {
				commit('SET_INDEX', {
					data: response.data,
					key: 'states',
				});
			})
			.catch((error) => {
				console.log(error);
			});
	},
	async getPublicHolidays({ commit }, year) {
		return this.axios
			.get('/index/holidays', {
				params: {
					year,
				},
			})
			.then((response) => {
				commit('SET_INDEX_HOLIDAYS', {
					data: response.data,
					year,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	},
};

const mutations = {
	LOGIN_SUCCESS(state, payload) {
		const user = {
			id: payload._id,
			email: payload.email,
			roles: payload.roles,
			accessToken: payload.accessToken,
		};

		state.user = { ...payload, ...user };
		this.axios.defaults.headers.common['Authorization'] = payload.accessToken;
		localStorage.setItem('user', JSON.stringify(user));
	},
	UPDATE_USER(state, payload) {
		if (payload._id === state.user.id) {
			const user = {
				id: payload._id,
				email: payload.email,
				roles: payload.roles,
				accessToken: state.user.accessToken,
			};

			state.user = { ...payload, ...user };

			localStorage.setItem('user', JSON.stringify(user));
		}
	},
	LOGIN_FAILURE(state) {
		state.user = null;
		state.status = 'failed';
	},
	LOGOUT_SUCCESS(state) {
		localStorage.removeItem('user');
		state.user = null;
		this.axios.defaults.headers.common['Authorization'] = '';
		state.status = 'initial';
	},
	LOGOUT_FAILURE(state) {
		state.status.loggedIn = false;
		state.user = null;
		state.status = 'failed';
	},
	SET_STATUS(state, status) {
		state.status = status;
	},
	SET_USER_EVENTS(state, payload) {
		state.userEvents = payload;
	},
	ADD_USER_EVENT(state, payload) {
		if (Array.isArray(payload)) {
			state.userEvents = state.userEvents.concat(payload);
		} else {
			state.userEvents.push(payload);
		}
	},
	UPDATE_USER_EVENT(state, payload) {
		state.userEvents = state.userEvents.map((event) =>
			event._id === payload._id ? payload : event
		);
	},
	DELETE_USER_EVENT(state, payload) {
		const index = state.userEvents.findIndex((e) => e._id === payload);
		if (index > -1) state.userEvents.splice(index, 1);
	},
	SET_USER_SHIFTS(state, payload) {
		state.userShifts = payload;
	},
	ADD_USER_SHIFT(state, payload) {
		if (Array.isArray(payload)) {
			state.userShifts = state.userShifts.concat(payload);
		} else {
			state.userShifts.push(payload);
		}
	},
	DELETE_USER_SHIFT(state, payload) {
		const index = state.userShifts.findIndex((e) => e._id === payload);
		if (index > -1) state.userShifts.splice(index, 1);
	},
	SET_INDEX(state, { key, data }) {
		if (state.index[key]) {
			state.index[key] = data;
		}
	},
	SET_INDEX_HOLIDAYS(state, { year, data }) {
		if (state.index.publicHolidays) {
			state.index.publicHolidays[year] = data;
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
