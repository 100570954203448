const state = {
	data: {},
	tableHeaders: [],
};

const getters = {};

const actions = {
	getAnalyseData({ commit }) {
		this.axios
			.get('/admin/analyse')
			.then((res) => {
				commit('SET_ANALYSE_DATA', res.data);
			})
			.catch((error) => {
				commit(
					'TRIGGER_TOAST',
					'Analyse Daten konnten nicht abgerufen werden',
					{
						root: true,
					}
				);
				console.log(error);
			});
	},
	getHeaders({ commit }) {
		this.axios
			.get('/admin/analyse/headers')
			.then((res) => {
				commit('SET_ANALYSE_HEADERS', res.data);
			})
			.catch((error) => {
				commit(
					'TRIGGER_TOAST',
					'Analyse Tabellendaten konnten nicht abgerufen werden',
					{
						root: true,
					}
				);
				console.log(error);
			});
	},
	downloadData({ commit }, { year, format }) {
		this.axios
			.get(
				`/admin/analyse/download?format=${format}&year=${encodeURIComponent(
					JSON.stringify(year)
				)}`
			)
			.then((res) => {
				if (res.data?.url) {
					window.open(res.data.url, '_blank');
				} else {
					commit('TRIGGER_TOAST', 'Fehler beim download', {
						root: true,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				commit('TRIGGER_TOAST', 'Fehler beim download', {
					root: true,
				});
			});
	},
};

const mutations = {
	SET_ANALYSE_DATA(state, payload) {
		if (payload) {
			state.data = payload;
		}
	},
	SET_ANALYSE_HEADERS(state, payload) {
		if (payload) {
			state.tableHeaders = payload;
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
